





























import { Component } from 'vue-property-decorator'
import SlideCarousel from '@/components/SlideCarousel.vue'
import SlideTimelineCarouselItem from '@/views/SlideTimelineCarouselItem.vue'
import { resolveSlug } from '@/utils/alias-resolver'
import { mixins } from 'vue-class-component'
import Slide from '@/mixins/Slide'

@Component({
    components: {
        SlideCarousel,
        SlideTimelineCarouselItem
    }
})
export default class SlideTimelineCarousel extends mixins(Slide) {
    get walkerItem() {
        return this.walker.item as SlideWalkerItemTimelineCarousel
    }

    resolveWalkerSlug(walker: SlideWalker | PresentationWalker) {
        return resolveSlug(walker)
    }

    goToSlide(index: number): void {
        this.$emit('go-to-slide', index)
    }
}
